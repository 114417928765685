export const colors = {
  blackish: "#212129",
  darkBlue: "#3B3B51",
  almostBlack: "#272735",
  white: "#ffffff",
  crimson: "#f9617b",
  brightCrimson: "#ff5b77",
  lightGray: "#e5e5ef",
  darkGray: "#a5a5b2",
  silver: "#f5f5fa",
  middleGray: "#c6c6d1",
  darkCrimson: "#e65a71",
  paleCrimson: "#e97085",
  gold: "#d4b335",
  paleGold: "#e1cb79",
  darkGold: "#bfad68",
  green: "#73a55d",
  lightPink: "#feeff1",
  purple: "#6e66a9",
  lightYellow: "#f8f4e3",
  lightGreen: "#eaf1e7",
};

export const indents = {
  xxs: 8,
  xs: 12,
  s: 16,
  m: 20,
  l: 24,
  xl: 28,
  xxl: 40,
};

export const fonts = {
  primary: "Stolzl",
  secondary: "DM Sans",
  tertiary: "IvyJournal",
};

export const sizes = {
  gridSm: 4,
  gridMd: 8,
  gridLg: 12,
  gridXl: 12,
  gutter: 20,
};

export const breakpoints = {
  xs: 360,
  sm: 768,
  md: 1024,
  lg: 1440,
  demo: 1124,
};

export const zIndex = {
  nprogress: 99,
  mobileMenu: 98,
  cookiesNotice: 96,
  dynamicHeader: 95,
};
