import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { colors } from "src/theme/variables";
import { mediaLg, mediaMd, mediaSm, transition } from "src/theme/utils";
import { Typography, Link, Button, Svg } from "src/theme/elements";
import { Container } from "src/theme/layout";

const getVariant = variant => {
  switch (variant) {
    case "dark":
      return css`
        background-color: ${colors.blackish};
      `;
    case "light":
      return css`
        background-color: ${colors.white};

        ${StyledLink} {
          color: ${colors.blackish};
        }

        ${StyledDivider} {
          opacity: 1;
        }

        ${StyledHamburger} {
          color: ${colors.blackish};
        }
      `;
    default:
      return "";
  }
};

const StyledRoot = styled.header`
  padding: 40px 0;
  transition: ${transition("background-color")};

  ${mediaLg`
    padding: 24px 0;
  `}

  ${mediaSm`
    padding: 10px 0;
  `}

  ${({ variant }) => getVariant(variant)}
`;

const StyledTypography = styled(Typography)``;

const StyledContainer = styled(Container)`
  display: flex;
  align-items: center;
`;

const StyledLogoContainer = styled(Link).attrs({
  to: "/",
})`
  display: flex;
  align-items: center;
`;

const StyledLogo = styled(Svg).attrs({
  path: "icons/appspector",
})`
  width: 40px;
  height: 40px;
  margin-right: 16px;
  color: ${colors.crimson};

  ${mediaMd`
    width: 36px;
    height: 36px;
    margin-right: 12px;
  `}

  ${mediaSm`
    width: 32px;
    height: 32px;
  `}
`;

const StyledAppName = styled(Typography)`
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  color: ${colors.crimson};

  ${mediaMd`
    font-size: 16px;
    line-height: 24px;
  `}
`;

const StyledMenu = styled.nav`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

const StyledLink = styled(Link).attrs({
  activeClassName: "link-active",
})`
  position: relative;
  padding: 6px 8px;
  margin: 0 8px;
  color: ${colors.lightGray};
  transition: ${transition("color")};

  ${mediaMd`
    padding: 4px 6px;
    margin: 0 6px;
  `}

  ${mediaSm`
    display: none;
  `}

  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, 4px);
    opacity: 0;
    width: calc(100% - 16px);
    height: 3px;
    background-color: ${colors.crimson};
    transition: ${transition(["transform", "opacity"])};

    ${mediaMd`
      width: calc(100% - 12px);
    `}
  }

  ${StyledTypography} {
    font-weight: 400;
    font-size: 16px;
    line-height: 1;
    color: inherit;

    ${mediaMd`
      font-size: 15px;
    `}
  }

  &.link-active,
  &:active,
  &:hover {
    color: ${colors.crimson};

    &::after {
      opacity: 1;
      transform: translate(-50%, 0);
    }
  }
`;

const StyledSignInLink = styled(StyledLink)`
  ${mediaSm`
    display: block;
  `}
`;

const StyledDivider = styled.div`
  width: 2px;
  height: 24px;
  margin: 0 8px;
  opacity: 0.15;
  background-color: ${colors.lightGray};
  transition: ${transition("opacity")};

  ${mediaMd`
    display: none;
  `}
`;

const StyledButton = styled(Button).attrs({
  variant: "secondary",
})`
  margin-left: 8px;

  ${mediaMd`
    margin-left: 6px;
  `}

  ${mediaSm`
    display: none;
  `}
`;

const StyledHamburger = styled.button`
  display: none;

  ${mediaSm`
    display: block;
    width: 44px;
    height: 44px;
    padding: 6px;
    margin-left: 6px;
    margin-right: -6px;
    background-color: transparent;
    color: ${colors.lightGray};
  `}
`;

const Header = ({ className, variant }) => {
  const mobileMenuRef = useRef();

  const openMobileMenu = () => {
    if (mobileMenuRef && mobileMenuRef.current) {
      mobileMenuRef.current.classList.add("isActive");
    }
  };

  useEffect(() => {
    mobileMenuRef.current = document.querySelector("[data-mobile-menu]");
  }, []);

  return (
    <StyledRoot className={className} variant={variant}>
      <StyledContainer>
        <StyledLogoContainer>
          <StyledLogo />
          <StyledAppName>AppSpector</StyledAppName>
        </StyledLogoContainer>
        <StyledMenu>
          <StyledLink to="/">
            <StyledTypography>Product</StyledTypography>
          </StyledLink>
          <StyledLink to="/pricing">
            <StyledTypography>Pricing</StyledTypography>
          </StyledLink>
          <StyledLink to="https://docs.appspector.com">
            <StyledTypography>Support</StyledTypography>
          </StyledLink>
          <StyledLink to="/blog">
            <StyledTypography>Blog</StyledTypography>
          </StyledLink>
          <StyledDivider />
          <StyledSignInLink to="https://app.appspector.com/login">
            <StyledTypography>Sign in</StyledTypography>
          </StyledSignInLink>
          <StyledButton to="/download">Download</StyledButton>
          {/* <StyledButton to="/signup">Try for free</StyledButton> */}
          <StyledHamburger aria-label="open mobile menu" onClick={openMobileMenu}>
            <Svg path="icons/menu" />
          </StyledHamburger>
        </StyledMenu>
      </StyledContainer>
    </StyledRoot>
  );
};

Header.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf(["dark", "light"]),
};

export default Header;
